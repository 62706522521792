import { usePrivy } from '@privy-io/react-auth';
import { useState, useLayoutEffect, useMemo } from 'react';

import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import ValidationRoutes from './ValidationRoutes';
import Loading from '../components/Loading';
import useSystem from '../hooks/useSystem';
import useCheckCreateWallet from '../hooks/useCheckCreateWallet';
import useUserProfile from '../hooks/useUserProfile';
import useUserGamePlay from '../hooks/useUserGamePlay';
import useUserWallet from '../hooks/useUserWallet';
import useLastOnlineTime from '../hooks/useLastOnlineTime';
import useSaveUserIpV6 from '../hooks/useSaveUserIpV6';
import useSystemStore from '../stores/system.store';
import useUserStore from '../stores/user.store';
import BannedModel from '../components/BannedModel';
import Warning from '../pages/Warning';
import PassCode from '../pages/PassCode';

const Navigations = () => {
  const { ready, authenticated, user, logout } = usePrivy();
  const { userWallet } = useUserWallet();
  const [isInAuthFlow, setIsInAuthFlow] = useState(false);

  useSystem();
  useUserProfile(ready, user, userWallet);
  useCheckCreateWallet(userWallet);
  useUserGamePlay();
  useLastOnlineTime();
  useSaveUserIpV6({ userId: user?.id });

  const configs = useSystemStore((state) => state.configs);
  const profile = useUserStore((state) => state.profile);
  const gamePlay = useUserStore((state) => state.gamePlay);

  const isLoading = useMemo(
    () => !ready || !configs || configs?.disabledUrls?.includes(window.location.host),
    [ready, configs, window.location.host]
  );
  const isBlocked = configs?.disabledUrls?.includes(window.location.host);

  useLayoutEffect(() => {
    if (!isLoading) {
      // add delay when moving away from /login page => privy has time to hide login ui
      if (authenticated) setTimeout(() => setIsInAuthFlow(false), 200);
      else setIsInAuthFlow(true);
    }
  }, [isLoading, authenticated]);

  if (isLoading) return <Loading isBlocked={isBlocked} />;

  if (!authenticated || isInAuthFlow) return <AuthRoutes />;

  if (!profile) return <Loading />;

  if (profile && !profile.tosAgreed) return <Warning />;

  if (configs.passCodeRequired && !profile.passCode) return <PassCode />;

  if (profile && !profile.socials?.twitter?.isUserAskedToConnect) return <ValidationRoutes />;
  if (gamePlay?.isSpam) return <BannedModel />;
  return <MainRoutes />;
};

export default Navigations;
