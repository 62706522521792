import Phaser from 'phaser';

import configs from '../../configs/configs';

const { width, gangsterAnimation, goonAnimation, thugAnimation } = configs;

const animationConfigs = [
  { start: 1, end: 5, count: 1, backMap: [[0, 0]], frontMap: [[0, 0]] },
  {
    start: 6,
    end: 10,
    count: 2,
    backMap: [
      [0, 0],
      [0, 1],
    ],
    frontMap: [
      [0, 0],
      [0, -1],
    ],
  },
  {
    start: 11,
    end: 29,
    count: 3,
    backMap: [
      [0, 0],
      [-0.5, 1],
      [1, 0],
    ],
    frontMap: [
      [0, 0],
      [-0.5, -1],
      [1, 0],
    ],
  },
  {
    start: 30,
    end: 99,
    count: 4,
    backMap: [
      [0, 0],
      [-0.5, 1],
      [1, 0],
      [-0.5, 1],
    ],
    frontMap: [
      [0, 0],
      [-0.5, -1],
      [1, 0],
      [-0.5, -1],
    ],
  },
  {
    start: 100,
    end: 199,
    count: 5,
    backMap: [
      [0, 0],
      [-0.5, 1],
      [1, 0],
      [-1, 1],
      [1, 0],
    ],
    frontMap: [
      [0, 0],
      [-0.5, -1],
      [1, 0],
      [-1, -1],
      [1, 0],
    ],
  },
  {
    start: 200,
    end: 499,
    count: 6,
    backMap: [
      [0, 0],
      [-0.5, 1],
      [1, 0],
      [-1.5, 1],
      [1, 0],
      [1, 0],
    ],
    frontMap: [
      [0, 0],
      [-0.5, -1],
      [1, 0],
      [-1.5, -1],
      [1, 0],
      [1, 0],
    ],
  },
  {
    start: 500,
    end: Infinity,
    count: 7,
    backMap: [
      [0, 0],
      [-1, 1],
      [1, 0],
      [1, 0],
      [-2, 1],
      [1, 0],
      [1, 0],
    ],
    frontMap: [
      [0, 0],
      [-1, -1],
      [1, 0],
      [1, 0],
      [-2, -1],
      [1, 0],
      [1, 0],
    ],
  },
];

const gangsterBackAnimationSpeed = {
  x: Math.abs(gangsterAnimation.back.end.x - gangsterAnimation.back.start.x) / gangsterAnimation.back.time,
  y: Math.abs(gangsterAnimation.back.end.y - gangsterAnimation.back.start.y) / gangsterAnimation.back.time,
  scale: Math.abs(gangsterAnimation.back.end.scale - gangsterAnimation.back.start.scale) / gangsterAnimation.back.time,
};

const gangsterFrontAnimationSpeed = {
  x: Math.abs(gangsterAnimation.front.end.x - gangsterAnimation.front.start.x) / gangsterAnimation.front.time,
  y: Math.abs(gangsterAnimation.front.end.y - gangsterAnimation.front.start.y) / gangsterAnimation.front.time,
  scale:
    Math.abs(gangsterAnimation.front.end.scale - gangsterAnimation.front.start.scale) / gangsterAnimation.front.time,
};

const goonBackAnimationSpeed = {
  x: Math.abs(goonAnimation.back.end.x - goonAnimation.back.start.x) / goonAnimation.back.time,
  y: Math.abs(goonAnimation.back.end.y - goonAnimation.back.start.y) / goonAnimation.back.time,
  scale: Math.abs(goonAnimation.back.end.scale - goonAnimation.back.start.scale) / goonAnimation.back.time,
};

const goonFrontAnimationSpeed = {
  x: Math.abs(goonAnimation.front.end.x - goonAnimation.front.start.x) / goonAnimation.front.time,
  y: Math.abs(goonAnimation.front.end.y - goonAnimation.front.start.y) / goonAnimation.front.time,
  scale: Math.abs(goonAnimation.front.end.scale - goonAnimation.front.start.scale) / goonAnimation.front.time,
};

const thugBackAnimationSpeed = {
  x: Math.abs(thugAnimation.back.end.x - thugAnimation.back.start.x) / thugAnimation.back.time,
  y: Math.abs(thugAnimation.back.end.y - thugAnimation.back.start.y) / thugAnimation.back.time,
  scale: Math.abs(thugAnimation.back.end.scale - thugAnimation.back.start.scale) / thugAnimation.back.time,
};

const thugFrontAnimationSpeed = {
  x: Math.abs(thugAnimation.front.end.x - thugAnimation.front.start.x) / thugAnimation.front.time,
  y: Math.abs(thugAnimation.front.end.y - thugAnimation.front.start.y) / thugAnimation.front.time,
  scale: Math.abs(thugAnimation.front.end.scale - thugAnimation.front.start.scale) / thugAnimation.front.time,
};

class Animation extends Phaser.GameObjects.Container {
  status = null;
  timeout = null;
  listeningOnUpdate = false;
  numberOfMachines = 0;
  numberOfWorkers = 0;
  numberOfThugs = 0;
  characterAnimations = {
    machine: { running: 'back', count: 0, characters: [], nextCoordinateIndexComparedToLatest: 0 },
    worker: { running: 'back', count: 0, characters: [], nextCoordinateIndexComparedToLatest: 0 },
    thug: { running: 'back', count: 0, characters: [], nextCoordinateIndexComparedToLatest: 0 },
  };

  constructor(scene) {
    super(scene, 0, 0);
    if (scene.name === 'TutorialScene') {
      this.status = 'open';
    }

    const animations = {
      gangsterFrontRun: 'gangster-front-run',
      gangsterBackRun: 'gangster-back-run',
      goonFrontRun: 'goon-front-run',
      goonBackRun: 'goon-back-run',
      thugFrontRun: 'thug-front-run',
      thugBackRun: 'thug-back-run',
    };
    this.animations = animations;

    this.coinbagPickupSound = scene.sound.add('coinbag-pickup', { loop: false });

    this.gangsterCounterContainer = scene.add.image(0, 0, 'counter').setOrigin(0.5, 0.5);
    this.gangsterCounterText = scene.add
      .text(0, 0, '0', {
        fontSize: '42px',
        color: '#311FA9',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.toggleMachineCounter(false);
    this.add(this.gangsterCounterContainer);
    this.add(this.gangsterCounterText);

    this.goonCounterContainer = scene.add.image(0, 0, 'counter').setOrigin(0.5, 0.5);
    this.goonCounterText = scene.add
      .text(0, 0, '0', {
        fontSize: '42px',
        color: '#311FA9',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.goonCounterContainer.setVisible(false);
    this.goonCounterText.setVisible(false);
    this.add(this.goonCounterContainer);
    this.add(this.goonCounterText);

    this.thugCounterContainer = scene.add.image(0, 0, 'counter').setOrigin(0.5, 0.5);
    this.thugCounterText = scene.add
      .text(0, 0, '0', {
        fontSize: '42px',
        color: '#311FA9',
        fontFamily: 'WixMadeforDisplayExtraBold',
      })
      .setOrigin(0.5, 0.5);
    this.thugCounterContainer.setVisible(false);
    this.thugCounterText.setVisible(false);
    this.add(this.thugCounterContainer);
    this.add(this.thugCounterText);

    const gangsterFrontFrames = scene.anims.generateFrameNames('gangster-front', {
      start: 1,
      end: 22,
      zeroPad: 5,
      prefix: 'gangster_front_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    const gangsterBackFrames = scene.anims.generateFrameNames('gangster-back', {
      start: 1,
      end: 22,
      zeroPad: 5,
      prefix: 'gangster_back_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    const goonFrontFrames = scene.anims.generateFrameNames('goon-front', {
      start: 1,
      end: 22,
      zeroPad: 5,
      prefix: 'goon_front_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    const goonBackFrames = scene.anims.generateFrameNames('goon-back', {
      start: 1,
      end: 22,
      zeroPad: 5,
      prefix: 'goon_back_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    const thugFrontFrames = scene.anims.generateFrameNames('thug-front', {
      start: 1,
      end: 19,
      zeroPad: 5,
      prefix: 'thug_front_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    const thugBackFrames = scene.anims.generateFrameNames('thug-back', {
      start: 1,
      end: 22,
      zeroPad: 5,
      prefix: 'thug_back_view_running_cycle_',
      suffix: '.png',
      frameRate: 10,
    });

    scene.anims.create({ key: animations.gangsterFrontRun, frames: gangsterFrontFrames, frameRate: 24, repeat: -1 });
    scene.anims.create({ key: animations.gangsterBackRun, frames: gangsterBackFrames, frameRate: 24, repeat: -1 });
    scene.anims.create({ key: animations.goonFrontRun, frames: goonFrontFrames, frameRate: 24, repeat: -1 });
    scene.anims.create({ key: animations.goonBackRun, frames: goonBackFrames, frameRate: 24, repeat: -1 });
    scene.anims.create({ key: animations.thugFrontRun, frames: thugFrontFrames, frameRate: 36, repeat: -1 }); // otherwise this one is slower than other ones
    scene.anims.create({ key: animations.thugBackRun, frames: thugBackFrames, frameRate: 24, repeat: -1 });

    this.thugLayer = scene.add.layer();
    this.workerLayer = scene.add.layer();
    this.machineLayer = scene.add.layer();

    if (['staging.gangsterarena.com', 'test.gangsterarena.com', 'localhost:3000'].includes(window.location.host)) {
      this.fpsText = scene.add
        .text(width - 50, 50, 'FPS: 0', {
          fontSize: '48px',
          color: 'white',
        })
        .setOrigin(1, 0);

      this.interval = setInterval(() => {
        this.updateFps();
      }, 1000);
    }

    scene.events.on('s-set-animation-assets', ({ numberOfMachines, numberOfWorkers, numberOfThugs }) => {
      if (this.status !== 'open') {
        this.numberOfMachines = 0;
        this.numberOfWorkers = 0;
        this.numberOfThugs = 0;
        return;
      }

      this.numberOfMachines = numberOfMachines;
      this.numberOfWorkers = numberOfWorkers;
      this.numberOfThugs = numberOfThugs;
      this.addUpdateEventListener();
    });

    scene.events.on('s-set-season-status', ({ status }) => {
      this.status = status;
      scene.events.emit('s-get-animation-assets');
    });
  }

  addUpdateEventListener() {
    if (this.listeningOnUpdate) return;
    this.scene.events.on('update', this.onSceneUpdate, this);
    this.listeningOnUpdate = true;
  }

  removeUpdateEventListener() {
    this.scene.events.off('update', this.onSceneUpdate, this);
    this.listeningOnUpdate = false;
  }

  // run when all assets = 0 || season status !== 'open'
  removeAllAnimations() {
    this.removeMachineAnimations();
    this.removeWorkerAnimations();
    this.removeThugAnimations();
  }

  removeMachineAnimations() {
    const { machine } = this.characterAnimations;
    for (const character of machine.characters) {
      this.remove(character);
      character.anims.stop();
      character.destroy();
    }

    this.gangsterCounterText.text = `0`;
    this.characterAnimations.machine = {
      running: 'back',
      count: 0,
      characters: [],
      nextCoordinateIndexComparedToLatest: 0,
    };

    this.toggleMachineCounter(false);
  }

  removeWorkerAnimations() {
    const { worker } = this.characterAnimations;
    for (const character of worker.characters) {
      this.remove(character);
      character.anims.stop();
      character.destroy();
    }

    this.goonCounterText.text = `0`;
    this.characterAnimations.worker = {
      running: 'back',
      count: 0,
      characters: [],
      nextCoordinateIndexComparedToLatest: 0,
    };

    this.toggleWorkerCounter(false);
  }

  removeThugAnimations() {
    const { thug } = this.characterAnimations;
    for (const character of thug.characters) {
      this.remove(character);
      character.anims.stop();
      character.destroy();
    }

    this.thugCounterText.text = `0`;
    this.characterAnimations.thug = {
      running: 'back',
      count: 0,
      characters: [],
      nextCoordinateIndexComparedToLatest: 0,
    };

    this.toggleThugCounter(false);
  }

  updateMachineCharacterCount() {
    const { machine } = this.characterAnimations;
    const direction = machine.running;

    const animationConfig = animationConfigs.find(
      (config) => config.start <= this.numberOfMachines && this.numberOfMachines <= config.end
    );
    const numberOfCharacters = animationConfig.count;

    while (numberOfCharacters > machine.count) {
      const lastCharacter = machine.characters.at(-1);
      let startX = lastCharacter ? lastCharacter.x : gangsterAnimation[direction].start.x;
      let startY = lastCharacter ? lastCharacter.y : gangsterAnimation[direction].start.y;
      let startScale = lastCharacter ? lastCharacter.scaleX : gangsterAnimation[direction].start.scale;

      if (direction === 'back') {
        const [rateX, rateY] = animationConfig.backMap[machine.nextCoordinateIndexComparedToLatest];
        startX += rateX * gangsterAnimation.back.gapX;
        startY += rateY * gangsterAnimation.back.gapY;
        machine.nextCoordinateIndexComparedToLatest = (machine.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      if (direction === 'front') {
        const [rateX, rateY] = animationConfig.frontMap[machine.nextCoordinateIndexComparedToLatest];
        startX += rateX * gangsterAnimation.front.gapX;
        startY += rateY * gangsterAnimation.front.gapY;
        machine.nextCoordinateIndexComparedToLatest = (machine.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      const newCharacter = this.scene.add.sprite(startX, startY).setScale(startScale).setVisible(!lastCharacter);
      newCharacter.play(direction === 'back' ? this.animations.gangsterBackRun : this.animations.gangsterFrontRun);

      machine.characters.push(newCharacter);
      machine.count++;
      this.machineLayer.add(newCharacter);
      if (machine.running === 'front') {
        this.machineLayer.sendToBack(newCharacter);
      }
    }

    while (numberOfCharacters < machine.count) {
      const lastCharacter = machine.characters.at(-1);
      lastCharacter.anims.stop();
      this.machineLayer.remove(lastCharacter);
      lastCharacter.destroy();
      machine.characters.pop();
      machine.count--;
    }

    this.gangsterCounterText.text = `${this.numberOfMachines}`;
  }

  checkSwitchDirectionMachineAnimations() {
    const { machine } = this.characterAnimations;

    if (machine.running === 'back') {
      const lastCharacter = machine.characters.at(-1);
      const end = gangsterAnimation.back.end.y > lastCharacter.y;
      if (end) {
        for (const character of machine.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.machine = {
          count: 0,
          running: 'front',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };

        this.coinbagPickupSound.play();
        this.updateMachineCharacterCount();
      }
    }

    if (machine.running === 'front') {
      const lastCharacter = machine.characters.at(-1);
      const end = gangsterAnimation.front.end.y < lastCharacter.y;
      if (end) {
        for (const character of machine.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.machine = {
          count: 0,
          running: 'back',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };
        this.requestClaimableReward();
        this.updateMachineCharacterCount();
      }
    }
  }

  updateMachineCharacterMovements(delta) {
    const { machine } = this.characterAnimations;
    if (machine.running === 'back') {
      const leader = machine.characters[0];
      const { x, y, scale } = leader;

      const newX = x + gangsterBackAnimationSpeed.x * delta;
      const newY = y - gangsterBackAnimationSpeed.y * delta;
      const newScale = Math.max(scale - gangsterBackAnimationSpeed.scale * delta, gangsterAnimation.back.end.scale);

      for (let i = 0; i < machine.count; i++) {
        const character = machine.characters[i];

        const newScale = Math.max(
          character.scale - gangsterBackAnimationSpeed.scale * delta,
          gangsterAnimation.back.end.scale
        );
        const newX = character.x + gangsterBackAnimationSpeed.x * delta;
        const newY = character.y - gangsterBackAnimationSpeed.y * delta;

        character.x = newX;
        character.y = newY;
        character.setScale(newScale);

        const visible = gangsterAnimation.back.start.y >= character.y && character.y >= gangsterAnimation.back.end.y;
        if (i === 0 && visible !== this.gangsterCounterContainer.visible) {
          this.toggleMachineCounter(visible);
        }

        character.setVisible(visible);
      }

      this.updateGangsterCounter(newX, newY - newScale * 500);

      this.checkSwitchDirectionMachineAnimations();
    }

    if (machine.running === 'front') {
      const leader = machine.characters[0];
      const { x, y, scale } = leader;
      const newX = x + gangsterFrontAnimationSpeed.x * delta;
      const newY = y + gangsterFrontAnimationSpeed.y * delta;
      const newScale = Math.min(scale + gangsterFrontAnimationSpeed.scale * delta, gangsterAnimation.front.end.scale);

      for (let i = 0; i < machine.count; i++) {
        const character = machine.characters[i];
        const newX = character.x + gangsterFrontAnimationSpeed.x * delta;
        const newY = character.y + gangsterFrontAnimationSpeed.y * delta;
        const newScale = Math.min(
          character.scale + gangsterFrontAnimationSpeed.scale * delta,
          gangsterAnimation.front.end.scale
        );

        character.x = newX;
        character.y = newY;
        character.setScale(newScale);

        const visible = gangsterAnimation.front.start.y <= character.y && character.y <= gangsterAnimation.front.end.y;
        if (i === 0 && visible !== this.gangsterCounterContainer.visible) {
          this.toggleMachineCounter(visible);
        }
        character.setVisible(visible);
      }

      this.updateGangsterCounter(newX, newY - newScale * 500);
      this.checkSwitchDirectionMachineAnimations();
    }
  }

  updateWorkerCharacterCount() {
    const { worker } = this.characterAnimations;
    const direction = worker.running;

    const animationConfig = animationConfigs.find(
      (config) => config.start <= this.numberOfWorkers && this.numberOfWorkers <= config.end
    );
    const numberOfCharacters = animationConfig.count;

    while (numberOfCharacters > worker.count) {
      const lastCharacter = worker.characters.at(-1);
      let startX = lastCharacter ? lastCharacter.x : goonAnimation[direction].start.x;
      let startY = lastCharacter ? lastCharacter.y : goonAnimation[direction].start.y;
      let startScale = lastCharacter ? lastCharacter.scaleX : goonAnimation[direction].start.scale;

      if (direction === 'back') {
        const [rateX, rateY] = animationConfig.backMap[worker.nextCoordinateIndexComparedToLatest];
        startX += rateX * goonAnimation.back.gapX;
        startY += rateY * goonAnimation.back.gapY;
        worker.nextCoordinateIndexComparedToLatest = (worker.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      if (direction === 'front') {
        const [rateX, rateY] = animationConfig.frontMap[worker.nextCoordinateIndexComparedToLatest];
        startX += rateX * goonAnimation.front.gapX;
        startY += rateY * goonAnimation.front.gapY;
        worker.nextCoordinateIndexComparedToLatest = (worker.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      const newCharacter = this.scene.add.sprite(startX, startY).setScale(startScale).setVisible(!lastCharacter);
      newCharacter.play(direction === 'back' ? this.animations.goonBackRun : this.animations.goonFrontRun);
      worker.characters.push(newCharacter);
      worker.count++;
      this.workerLayer.add(newCharacter);
      if (worker.running === 'front') {
        this.workerLayer.sendToBack(newCharacter);
      }
    }

    while (numberOfCharacters < worker.count) {
      const lastCharacter = worker.characters.at(-1);
      lastCharacter.anims.stop();
      this.workerLayer.remove(lastCharacter);
      lastCharacter.destroy();
      worker.characters.pop();
      worker.count--;
    }

    this.goonCounterText.text = `${this.numberOfWorkers}`;
  }

  checkSwitchDirectionWorkerAnimations() {
    const { worker } = this.characterAnimations;

    if (worker.running === 'back') {
      const lastCharacter = worker.characters.at(-1);
      const end = goonAnimation.back.end.y > lastCharacter.y;
      if (end) {
        for (const character of worker.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.worker = {
          count: 0,
          running: 'front',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };

        this.coinbagPickupSound.play();
        this.updateWorkerCharacterCount();
      }
    }

    if (worker.running === 'front') {
      const lastCharacter = worker.characters.at(-1);
      const end = goonAnimation.front.end.y < lastCharacter.y;
      if (end) {
        for (const character of worker.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.worker = {
          count: 0,
          running: 'back',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };

        this.requestClaimableReward();
        this.updateWorkerCharacterCount();
      }
    }
  }

  updateWorkerCharacterMovements(delta) {
    const { worker } = this.characterAnimations;
    if (worker.running === 'back') {
      const leader = worker.characters[0];
      const { x, y, scale } = leader;

      const newX = x - goonBackAnimationSpeed.x * delta;
      const newY = y - goonBackAnimationSpeed.y * delta;
      const newScale = Math.max(scale - goonBackAnimationSpeed.scale * delta, goonAnimation.back.end.scale);

      for (let i = 0; i < worker.count; i++) {
        const character = worker.characters[i];
        const newX = character.x - goonBackAnimationSpeed.x * delta;
        const newY = character.y - goonBackAnimationSpeed.y * delta;
        const newScale = Math.max(character.scale - goonBackAnimationSpeed.scale * delta, goonAnimation.back.end.scale);

        character.x = newX;
        character.y = newY;
        character.setScale(newScale);

        const visible = goonAnimation.back.start.y >= character.y && character.y >= goonAnimation.back.end.y;
        if (i === 0 && visible !== this.goonCounterContainer.visible) {
          this.toggleWorkerCounter(visible);
        }
        character.setVisible(visible);
      }

      this.updateGoonCounter(newX, newY - newScale * 500);
      this.checkSwitchDirectionWorkerAnimations();
    }

    if (worker.running === 'front') {
      const leader = worker.characters[0];
      const { x, y, scale } = leader;
      const newY = y + goonFrontAnimationSpeed.y * delta;
      const newScale = Math.min(scale + goonFrontAnimationSpeed.scale * delta, goonAnimation.front.end.scale);

      for (let i = 0; i < worker.count; i++) {
        const character = worker.characters[i];

        character.x = character.x + goonFrontAnimationSpeed.x * delta;
        character.y = character.y + goonFrontAnimationSpeed.y * delta;
        const newScale = Math.min(
          character.scale + goonFrontAnimationSpeed.scale * delta,
          goonAnimation.front.end.scale
        );
        character.setScale(newScale);

        const visible = goonAnimation.front.start.y <= character.y && character.y <= goonAnimation.front.end.y;
        if (i === 0 && visible !== this.goonCounterContainer.visible) {
          this.toggleWorkerCounter(visible);
        }
        character.setVisible(visible);
      }

      this.updateGoonCounter(x, newY - newScale * 500);
      this.checkSwitchDirectionWorkerAnimations();
    }
  }

  updateThugCharacterCount() {
    const { thug } = this.characterAnimations;
    const direction = thug.running;

    const animationConfig = animationConfigs.find(
      (config) => config.start <= this.numberOfThugs && this.numberOfThugs <= config.end
    );
    const numberOfCharacters = animationConfig.count;

    while (numberOfCharacters > thug.count) {
      const lastCharacter = thug.characters.at(-1);
      let startX = lastCharacter ? lastCharacter.x : thugAnimation[direction].start.x;
      let startY = lastCharacter ? lastCharacter.y : thugAnimation[direction].start.y;
      let startScale = lastCharacter ? lastCharacter.scaleX : thugAnimation[direction].start.scale;

      if (direction === 'back') {
        const [rateX, rateY] = animationConfig.backMap[thug.nextCoordinateIndexComparedToLatest];
        startX += rateX * thugAnimation.back.gapX;
        startY += rateY * thugAnimation.back.gapY;
        thug.nextCoordinateIndexComparedToLatest = (thug.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      if (direction === 'front') {
        const [rateX, rateY] = animationConfig.frontMap[thug.nextCoordinateIndexComparedToLatest];
        startX += rateX * thugAnimation.front.gapX;
        startY += rateY * thugAnimation.front.gapY;
        thug.nextCoordinateIndexComparedToLatest = (thug.nextCoordinateIndexComparedToLatest + 1) % 7;
      }

      const newCharacter = this.scene.add.sprite(startX, startY).setScale(startScale).setVisible(!lastCharacter);
      newCharacter.play(direction === 'back' ? this.animations.thugBackRun : this.animations.thugFrontRun);

      thug.characters.push(newCharacter);
      thug.count++;
      this.thugLayer.add(newCharacter);
      if (thug.running === 'front') {
        this.thugLayer.sendToBack(newCharacter);
      }
    }

    while (numberOfCharacters < thug.count) {
      const lastCharacter = thug.characters.at(-1);
      lastCharacter.anims.stop();
      this.thugLayer.remove(lastCharacter);
      lastCharacter.destroy();
      thug.characters.pop();
      thug.count--;
    }

    this.thugCounterText.text = `${this.numberOfThugs}`;
  }

  checkSwitchDirectionThugAnimations() {
    const { thug } = this.characterAnimations;

    if (thug.running === 'back') {
      const lastCharacter = thug.characters.at(-1);
      const end = thugAnimation.back.end.y > lastCharacter.y;
      if (end) {
        for (const character of thug.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.thug = {
          count: 0,
          running: 'front',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };

        this.coinbagPickupSound.play();
        this.updateThugCharacterCount();
      }
    }

    if (thug.running === 'front') {
      const lastCharacter = thug.characters.at(-1);
      const end = thugAnimation.front.end.y < lastCharacter.y;
      if (end) {
        for (const character of thug.characters) {
          this.remove(character);
          character.anims.stop();
          character.destroy();
        }
        this.characterAnimations.thug = {
          count: 0,
          running: 'back',
          characters: [],
          nextCoordinateIndexComparedToLatest: 0,
        };

        this.requestPoorTokenBalance();
        this.updateThugCharacterCount();
      }
    }
  }

  updateThugCharacterMovements(delta) {
    const { thug } = this.characterAnimations;
    if (thug.running === 'back') {
      const leader = thug.characters[0];
      const { x, y, scale } = leader;

      const newX = x + thugBackAnimationSpeed.x * delta;
      const newY = y - thugBackAnimationSpeed.y * delta;
      const newScale = Math.max(scale - thugBackAnimationSpeed.scale * delta, thugAnimation.back.end.scale);

      for (let i = 0; i < thug.count; i++) {
        const character = thug.characters[i];

        character.x = character.x + thugBackAnimationSpeed.x * delta;
        character.y = character.y - thugBackAnimationSpeed.y * delta;
        const newScale = Math.max(character.scale - thugBackAnimationSpeed.scale * delta, thugAnimation.back.end.scale);
        character.setScale(newScale);

        const visible = thugAnimation.back.start.y >= character.y && character.y >= thugAnimation.back.end.y;
        if (i === 0 && visible !== this.thugCounterContainer.visible) {
          this.toggleThugCounter(visible);
        }
        character.setVisible(visible);
      }

      this.updateThugCounter(newX, newY - newScale * 500);

      this.checkSwitchDirectionThugAnimations();
    }

    if (thug.running === 'front') {
      const leader = thug.characters[0];
      const { x, y, scale } = leader;
      const newX = x + thugFrontAnimationSpeed.x * delta;
      const newY = y + thugFrontAnimationSpeed.y * delta;
      const newScale = Math.min(scale + thugFrontAnimationSpeed.scale * delta, thugAnimation.front.end.scale);

      for (let i = 0; i < thug.count; i++) {
        const character = thug.characters[i];

        character.x = character.x + thugFrontAnimationSpeed.x * delta;
        character.y = character.y + thugFrontAnimationSpeed.y * delta;
        const newScale = Math.min(
          character.scale + thugFrontAnimationSpeed.scale * delta,
          thugAnimation.front.end.scale
        );
        character.setScale(newScale);

        const visible = thugAnimation.front.start.y <= character.y && character.y <= thugAnimation.front.end.y;
        if (i === 0 && visible !== this.thugCounterContainer.visible) {
          this.toggleThugCounter(visible);
        }
        character.setVisible(visible);
      }

      this.updateThugCounter(newX, newY - newScale * 500);
      this.checkSwitchDirectionThugAnimations();
    }
  }

  updateMachineAnimations(delta) {
    if (!this.numberOfMachines) {
      this.removeMachineAnimations();
      return;
    }

    if (!this.characterAnimations.machine.count) {
      this.updateMachineCharacterCount();
    }
    this.updateMachineCharacterMovements(delta);
  }

  updateWorkerAnimations(delta) {
    if (!this.numberOfWorkers) {
      this.removeWorkerAnimations();
      return;
    }

    if (!this.characterAnimations.worker.count) {
      this.updateWorkerCharacterCount();
    }
    this.updateWorkerCharacterMovements(delta);
  }

  updateThugAnimations(delta) {
    if (!this.numberOfThugs) {
      this.removeThugAnimations();
      return;
    }

    if (!this.characterAnimations.thug.count) {
      this.updateThugCharacterCount();
    }
    this.updateThugCharacterMovements(delta);
  }

  onSceneUpdate(time, delta) {
    if (this.numberOfMachines + this.numberOfThugs + this.numberOfWorkers === 0) {
      this.removeUpdateEventListener();
      this.removeAllAnimations();
      return;
    }

    this.updateThugAnimations(delta);
    this.updateWorkerAnimations(delta);
    this.updateMachineAnimations(delta);
  }

  requestClaimableReward() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.scene.events.emit('s-get-claimable-reward');
    }, 200);
  }

  requestPoorTokenBalance() {
    this.scene.events.emit('s-get-poor-token-balance');
  }

  toggleMachineCounter(visible) {
    this.gangsterCounterContainer.setVisible(visible);
    this.gangsterCounterText.setVisible(visible);
  }

  toggleWorkerCounter(visible) {
    this.goonCounterContainer.setVisible(visible);
    this.goonCounterText.setVisible(visible);
  }

  toggleThugCounter(visible) {
    this.thugCounterContainer.setVisible(visible);
    this.thugCounterText.setVisible(visible);
  }

  updateGangsterCounter(x, y) {
    this.gangsterCounterContainer.x = x;
    this.gangsterCounterContainer.y = y;
    this.gangsterCounterText.x = x;
    this.gangsterCounterText.y = y;
  }

  updateGoonCounter(x, y) {
    this.goonCounterContainer.x = x;
    this.goonCounterContainer.y = y;
    this.goonCounterText.x = x;
    this.goonCounterText.y = y;
  }

  updateThugCounter(x, y) {
    this.thugCounterContainer.x = x;
    this.thugCounterContainer.y = y;
    this.thugCounterText.x = x;
    this.thugCounterText.y = y;
  }

  cleanup() {
    this.removeAllAnimations();
    this.machineLayer.destroy();
    this.workerLayer.destroy();
    this.thugLayer.destroy();
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  updateFps() {
    if (!['staging.gangsterarena.com', 'test.gangsterarena.com', 'localhost:3000'].includes(window.location.host))
      return;

    this.fpsText.text = `FPS: ${this.scene.game.loop.actualFps.toFixed(0)}`;
  }
}

export default Animation;
