import Popup from './Popup';
import TextButton from '../button/TextButton';
import Button from '../button/Button';
import { fontFamilies } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import { getTwitterIntentUrl } from '../../../../utils/strings';

const buttonWidth = 506;
const btnGap = 50;

const textStyle = {
  fontSize: '50px',
  fontFamily: fontFamilies.extraBold,
  color: '#29000B',
};

class PopupRewards extends Popup {
  rewardsData = null;
  template = null;

  constructor(scene) {
    super(scene, 'popup-rewards', { title: 'Rewards' });
    this.scene = scene;

    this.backBtn = new TextButton(
      scene,
      this.popup.x - buttonWidth / 2 - btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.close();
      },
      'Back',
      { sound: 'close', fontSize: '82px' }
    );
    this.add(this.backBtn);

    this.shareBtn = new Button(
      scene,
      this.popup.x + buttonWidth / 2 + btnGap / 2,
      this.popup.y + this.popup.height / 2 - 20,
      'button-reward-share',
      'button-reward-share-pressed',
      () => {
        this.share();
      },
      { sound: 'button-1', disabledImage: 'button-reward-share-disabled' }
    );
    this.shareBtn.setDisabledState(true);
    this.add(this.shareBtn);

    const startingY = this.popup.y - this.popup.height / 2;
    const textX = this.popup.x + this.popup.width / 2 - 200;
    const textGap = 158;
    this.reputationText = scene.add.text(textX, startingY + 330, '---', textStyle).setOrigin(1, 0.5);
    this.stashEthText = scene.add
      .text(textX - 290, this.reputationText.y + textGap, '---', textStyle)
      .setOrigin(1, 0.5);
    this.stashTokenText = scene.add.text(textX - 70, this.stashEthText.y, '---', textStyle).setOrigin(1, 0.5);
    this.tokenText = scene.add.text(textX, startingY + 820, '---', textStyle).setOrigin(1, 0.5);
    this.ethText = scene.add.text(textX, this.tokenText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.blastGoldText = scene.add.text(textX, this.ethText.y + textGap, '---', textStyle).setOrigin(1, 0.5);
    this.xUText = scene.add.text(textX, this.blastGoldText.y + textGap, '---', textStyle).setOrigin(1, 0.5);

    this.add(this.reputationText);
    this.add(this.stashEthText);
    this.add(this.stashTokenText);
    this.add(this.tokenText);
    this.add(this.ethText);
    this.add(this.blastGoldText);
    this.add(this.xUText);

    scene.events.on('s-set-user-rewards', (data) => {
      const {
        networth,
        raidPoint,
        stashEth,
        stashToken,
        ethReward,
        tokenReward,
        blastGoldReward,
        xUReward,
        ethDistributed,
        tokenDistributed,
        blastGoldDistributed,
        completed,
        template,
      } = data;
      console.log('rewards', data);
      this.template = template;
      this.reputationText.text = networth !== null ? formatter.format(networth) : '---';
      this.stashEthText.text = stashEth !== null ? formatter.format(stashEth) : '---';
      this.stashTokenText.text = stashToken !== null ? customFormat(stashToken, 1) : '---';
      this.tokenText.text =
        tokenReward !== null ? `${customFormat(tokenDistributed, 2)}/${customFormat(tokenReward, 2)}` : '---';
      this.ethText.text =
        ethReward !== null ? `${formatter.format(ethDistributed)}/${formatter.format(ethReward)}` : '---';
      this.blastGoldText.text =
        blastGoldReward !== null
          ? `${formatter.format(blastGoldDistributed)}/${formatter.format(blastGoldReward)}`
          : '---';
      this.xUText.text = xUReward !== null ? formatter.format(xUReward) : '---';
      this.shareBtn.setDisabledState(!completed);
      if (completed) {
        this.rewardsData = {
          networth,
          stashEth,
          stashToken,
          ethReward,
          tokenReward,
          blastGoldReward,
          xUReward,
        };
      }
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-user-rewards');
  }

  share() {
    if (!this.template || !this.rewardsData) return;
    const text = this.template
      .replace('{ethReward}', formatter.format(this.rewardsData.ethReward))
      .replace('{tokenReward}', formatter.format(this.rewardsData.tokenReward))
      .replace('{siteUrl}', window.location.origin);
    const intentUrl = getTwitterIntentUrl({ text });
    window.open(intentUrl);
  }
}

export default PopupRewards;
