import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';

const { width, height } = configs;
const largeBlackBold = { fontSize: fontSizes.large, color: colors.black, fontFamily: fontFamilies.bold };
const lineGap = 100;
class PopupPrizePool extends Popup {
  constructor(scene) {
    super(scene, 'popup-mini', { title: 'Total Prize Pool', titleIcon: 'icon-info', noCloseBtn: true });
    this.scene = scene;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const paddedX = leftMargin + this.popup.width * 0.1;
    const startingY = this.popup.y - this.popup.height / 2;
    const firstParagraphY = startingY + 220;

    this.line1 = scene.add.text(
      paddedX,
      firstParagraphY,
      '-- ETH + --% of purchases.\n$GOLD rewards vest over -- days.',
      largeBlackBold
    );
    this.line2 = scene.add.text(
      paddedX,
      this.line1.y + this.line1.height + lineGap,
      '-- $xU (--%) from Uncharted\nfoundation.',
      // '-- ETH + --% of $GOLD trading fees.',
      largeBlackBold
    );
    this.line3 = scene.add.text(
      paddedX,
      this.line2.y + this.line2.height + lineGap,
      '-- Gold from Blast.',
      largeBlackBold
    );
    this.line4 = scene.add.text(
      paddedX,
      this.line3.y + this.line3.height + lineGap,
      '',
      // '-- Gold from Blast.',
      largeBlackBold
    );
    this.add(this.line1);
    this.add(this.line2);
    this.add(this.line3);
    this.add(this.line4);

    this.buttonBack = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        if (this.table) {
          this.table.setMouseWheelScrollerEnable(false);
        }
        this.close();
        scene.popupLeaderboard.open();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.buttonBack);

    scene.events.on('s-set-prize-pool-info', ({ prizePoolConfig, xUPool, blastGoldReward }) => {
      const { prizePoolEthBase, unitSpendToPrizePoolPercent, tokenPrizeVestDuration, totalUnchartedXU } =
        prizePoolConfig;
      const xUPercent = xUPool / totalUnchartedXU;
      this.line1.text = `${formatter.format(prizePoolEthBase)} ETH + ${
        unitSpendToPrizePoolPercent * 100
      }% of purchases.\n$GOLD rewards vest over ${tokenPrizeVestDuration} days.`;
      this.line2.text = `${customFormat(xUPool || 0, 2)} $xU (${xUPercent * 100}%) from Uncharted\nfoundation.`;
      this.line3.text = `${customFormat(blastGoldReward || 0, 2)} Blast Gold.`;
    });
  }

  onOpen() {
    this.scene.events.emit('s-get-prize-pool-info');
  }
}

export default PopupPrizePool;
