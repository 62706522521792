import Phaser from 'phaser';

import { customFormat } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width } = configs;

class GangsterHouse extends Phaser.GameObjects.Container {
  constructor(scene, y) {
    super(scene, 0, 0);

    this.house = scene.add.sprite(width / 2, y, '').setOrigin(0.5, 0.5);
    this.tree = scene.add.image(width / 2 + 400, y + 50, 'tree-xmas').setDisplaySize(324, 716);

    this.sign = scene.add.image(width / 2, y, 'gangster-house-sign-xmas').setOrigin(0.5, 0.5);
    this.valueText = scene.add
      .text(width / 2 + 90, this.sign.y + 20, ``, {
        fontSize: '82px',
        fontFamily: 'WixMadeforDisplayExtraBold',
        color: '#fff',
      })
      .setOrigin(0.5, 0.5);
    this.valueText.setStroke('#3D145F', 12);

    this.add(this.house);
    this.add(this.sign);
    this.add(this.tree);
    this.add(this.valueText);

    scene.events.on('s-set-networth-house', ({ networth, level }) => {
      this.valueText.text = networth < 10000 ? `${networth}` : `${customFormat(networth, 2)}`;
      if (level && this.scene) {
        this.house.setTexture(`gangster-house-${level}-xmas`);
      }
    });
  }
}

export default GangsterHouse;
