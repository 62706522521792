import Phaser from 'phaser';

import configs from '../configs/configs';
import Background from '../components/common/Background';
import PopupWelcomeNoWar from '../components/popup/PopupWelcomeNoWar';
import PopupWelcomeEndGame from '../components/popup/PopupWelcomeEndGame';
import PopupWelcomeWar from '../components/popup/PopupWelcomeWar';
import Header from '../components/action-buttons/Header';
import InfoButtons from '../components/action-buttons/InfoButtons';
import GangsterHouse from '../components/common/GangsterHouse';
import Footer from '../components/action-buttons/Footer';
import PopupBuy from '../components/popup/PopupBuy';
import PopupWar from '../components/popup/PopupWar';
import PopupSettings from '../components/popup/PopupSettings';
import PopupSwap from '../components/popup/PopupSwap';
import PopupWarHistory from '../components/popup/PopupWarHistory';
import PopupSafeHouseUpgrade from '../components/popup/PopupSafeHouseUpgrade';
import PopupBuyGoon from '../components/popup/PopupBuyGoon';
import PopupBuyThug from '../components/popup/PopupBuyThug';
import PopupBuyGangster from '../components/popup/PopupBuyGangster';
import PopupPortfolio from '../components/popup/PopupPortfolio';
import Animation from '../components/common/Animation';
import PopupStatistic from '../components/popup/PopupStatistic';
import PopupLeaderboard from '../components/popup/PopupLeaderboard';
import PopupDeposit from '../components/popup/PopupDeposit';
import PopupDepositETH from '../components/popup/PopupDepositETH';
import PopupReferralProgram from '../components/popup/PopupReferralProgram';
import PopupPrizePool from '../components/popup/PopupPrizePool';
import PopupWarMachines from '../components/popup/PopupWarMachines';
import PopupWarExplain from '../components/popup/PopupWarExplain';
import PopupWarAttack from '../components/popup/PopupWarAttack';
import PopupWarAttackDetail from '../components/popup/PopupWarAttackDetail';
import PopupWarHistoryDetail from '../components/popup/PopupWarHistoryDetail';
import PopupWarUpgrades from '../components/popup/PopupWarUpgrades';
import PopupGoonPrice from '../components/popup/PopupGoonPrice';
import PopupSafehousePrice from '../components/popup/PopupSafehousePrice';
import PopupGangsterPrice from '../components/popup/PopupGangsterPrice';
import PopupDailySpin from '../components/popup/PopupDailySpin';
import PopupSpinReward from '../components/popup/PopupSpinReward';
import PopupSpinHistory from '../components/popup/PopupSpinHistory';
import PopupLinkX from '../components/popup/PopupLinkX';
import PopupGangwarRequirement from '../components/popup/PopupGangwarRequirement';
import PopupAirdrop from '../components/popup/PopupAirdrop';
import PopupThugAirdrop from '../components/popup/PopupThugAirdrop';
import PopupLinkingMetamask from '../components/popup/PopupLinkingMetamask';
import PopupClaimFreeThug from '../components/popup/PopupClaimFreeThug';
import PopupRewards from '../components/popup/PopupRewards';
import PopupStarterPack from '../components/popup/PopupStarterPack';
import PopupClaimConfirm from '../components/popup/PopupClaimConfirm';
import PopupAugments from '../components/popup/PopupAugments';
import PopupAugmentsPending from '../components/popup/PopupAugmentsPending';
import PopupGDLDeposit from '../components/popup/PopupGDLDeposit';
import PopupPrivyDeposit from '../components/popup/PopupPrivyDeposit';
import PopupWarUpgradeExplain from '../components/popup/PopupWarUpgradeExplain';
import PopupDepositConfirm from '../components/popup/PopupDepositConfirm';

const { width } = configs;

class MainScene extends Phaser.Scene {
  status = null;
  isFromTutorial = false;
  timeout = null;
  startGamePopup = {
    welcomeBackLoaded: false,
    claimedFreeThugLoaded: false,
    starterPackLoaded: false,
    open: null,
    data: null,
    hasOpened: false,
  };
  globalEvents = [];

  constructor() {
    super('MainScene');
  }

  init(data) {
    const { isFromTutorial } = data;
    this.isFromTutorial = isFromTutorial;
  }

  // listeners for global events here
  addGlobalEventListener(name, fn) {
    this.globalEvents.push(name);
    this.game.events.on(name, fn, this);
  }

  createLocalToGlobalEventFlow(name) {
    this.events.on(`s-${name}`, (data) => this.game.events.emit(`g-${name}`, data), this);
  }

  createGlobalToLocalEventFlow(name) {
    this.addGlobalEventListener(`g-${name}`, (data) => this.events.emit(`s-${name}`, data));
  }

  initListeners() {
    // create event flow
    const localToGlobalEvents = [
      'get-animation-assets',
      'get-poor-token-balance',
      'get-claimable-reward',
      'get-deposit-code',
      'get-eth-balance',
      'get-war-data',
      'export-wallet',
      'log-out',
      'link-twitter',
      'unlink-twitter',
      'toggle-game-sound',
      'get-profile',
      'get-app-version',
      'swap',
      'convert-eth-input-to-token',
      'convert-token-input-to-eth',
      'get-gas-swap',
      'apply-invite-code',
      'get-invite-code',
      'get-referral-config',
      'get-referral-code',
      'get-referral-data',
      'get-statistic',
      'get-user-to-attack-detail',
      'get-war-config',
      'get-war-history',
      'get-next-war-time',
      'get-war-history-detail',
      'get-augment-configs',
      'get-user-augments',
      'roll-augments',
      'select-augment',
      'get-user-rewards',
      'link-wagmi-wallet',
      'get-airdrop-data',
      'get-goon-price',
      'get-house-price',
      'get-gangster-price',
      'get-prize-pool-info',
      'get-user-list-to-attack',
      'get-spin-rewards',
      'start-spin',
      'get-spin-history',
      'get-starter-pack-available',
      'get-twitter-share-thug-airdrop-template',
      'claim-thug-airdrop',
      'get-twitter-share-free-thugs-template',
      'get-number-of-free-thugs',
      'claim-free-thugs',
      'buy-starter-pack',
      'get-starter-pack-config',
      'get-market-data',
      'enable-machine-sales-tracking',
      'enable-worker-sales-tracking',
      'disable-machine-sales-tracking',
      'disable-worker-sales-tracking',
      'get-portfolio',
      'get-leaderboard-list',
      'war-upgrade-start',
      'get-gas-buy-goon',
      'get-war-upgrades',
      'get-referral-discount',
      'upgrade-safehouse',
      'enable-building-sales-tracking',
      'disable-building-sales-tracking',
      'get-gas-upgrade-safehouse',
      'get-networth',
      'buy-goon',
      'buy-thug',
      'enable-thug-sales-tracking',
      'disabled-thug-sales-tracking',
      'buy-gangster',
      'get-gas-mint',
      'update-war-machines',
      'get-game-play',
      'get-season',
      'get-rank',
      'get-end-game-reward',
      'claim',
      'get-claimable-status',
      'get-wallet-nft-balance',
      'get-wallet-nft-unstaked',
      'deposit-nft',
      'update-last-time-seen-war-result',
      'get-war-history-latest',
      'get-balances-for-withdraw',
      'withdraw-eth',
      'withdraw-nft',
      'withdraw-token',
      'get-deposit-address',
      'buy-crypto-with-card',
      'fund-wallet',
      'get-war-upgrade-explain',
      'get-machines',
      'get-workers',
      'get-buildings',
      'get-thugs',
    ];
    const globalToLocalEvents = [
      'set-animation-assets',
      'set-season-status',
      'set-poor-token-balance',
      'set-networth-house',
      'set-deposit-code',
      'set-eth-balance',
      'set-balances',
      'set-x-token-balance',
      'claim-completed',
      'set-war-data',
      'twitter-done',
      'game-sound-changed',
      'set-profile',
      'set-app-version',
      'swap-started',
      'swap-completed',
      'convert-eth-input-to-token-result',
      'convert-token-input-to-eth-result',
      'swap-error',
      'set-gas-swap',
      'complete-apply-invite-code',
      'set-invite-code',
      'set-referral-config',
      'set-referral-code',
      'set-referral-data',
      'set-statistic',
      'set-user-to-attack-detail',
      'set-war-config',
      'set-war-history',
      'set-next-war-time',
      'set-war-history-detail',
      'set-augment-configs',
      'set-user-augments',
      'set-user-rewards',
      'set-airdrop-data',
      'signing-wagmi-wallet',
      'link-wagmi-wallet-success',
      'link-wagmi-wallet-error',
      'set-goon-price',
      'set-workers',
      'set-house-price',
      'set-buildings',
      'set-gangster-price',
      'set-machines',
      'set-prize-pool-info',
      'set-user-list-to-attack',
      'set-user-list-to-attack-failed',
      'set-auth',
      'set-spin-rewards',
      'spin-error',
      'spin-result',
      'set-spin-history',
      'set-spin-history-error',
      'set-starter-pack-available',
      'set-twitter-share-thug-airdrop-template',
      'claim-thug-airdrop-done',
      'set-twitter-share-free-thugs-template',
      'set-number-of-free-thugs',
      'claim-free-thugs-done',
      'roll-augments-completed',
      'select-augment-completed',
      'buy-starter-pack-completed',
      'set-starter-pack-config',
      'set-market-data',
      'set-portfolio',
      'set-portfolio-rewards',
      'war-upgrade-completed',
      'set-gas-buy-goon',
      'set-war-upgrades',
      'set-referral-discount',
      'upgrade-safehouse-completed',
      'set-gas-upgrade-safehouse',
      'set-networth',
      'buy-goon-completed',
      'buy-thug-completed',
      'set-thugs',
      'buy-gangster-completed',
      'set-gas-mint',
      'update-war-machines-completed',
      'update-war-machines-error',
      'set-game-play',
      'set-season',
      'set-leaderboard-list',
      'set-rank',
      'set-end-game-reward',
      'claim-completed',
      'set-claimable-status',
      'set-claimable-reward',
      'claimable-reward-added',
      'set-claim-time',
      'set-wallet-nft-balance',
      'set-wallet-nft-unstaked',
      'deposit-nft-started',
      'deposit-nft-completed',
      'set-war-history-latest',
      'set-balances-for-withdraw',
      'withdraw-token-started',
      'withdraw-token-completed',
      'withdraw-eth-started',
      'withdraw-eth-completed',
      'withdraw-nft-started',
      'withdraw-nft-completed',
      'set-claim-cool-down',
      'set-deposit-address',
      'set-war-upgrade-explain',
    ];

    for (const event of localToGlobalEvents) {
      this.createLocalToGlobalEventFlow(event);
    }

    for (const event of globalToLocalEvents) {
      this.createGlobalToLocalEventFlow(event);
    }

    // interact with game components
    this.events.on('s-music-on', () => {
      this.bgMusic.play();
      this.game.sound.setMute(false);
    });

    this.events.on('s-music-off', () => {
      this.bgMusic.stop();
      this.game.sound.setMute(true);
    });

    this.events.on('update-can-buy-starter-pack', ({ userCanBuy }) => {
      if (this.startGamePopup.open !== 'free-thugs' && userCanBuy) {
        this.startGamePopup.open = 'starter-pack';
        this.startGamePopup.data = {};
      }
      this.startGamePopup.starterPackLoaded = true;

      this.openFirstPopup();
    });

    // listen to global event
    this.addGlobalEventListener('g-set-season-status', ({ status }) => {
      this.status = status;
      const ended = ['closed', 'completed'].includes(status);

      if (ended && !this.popupEndGame) {
        this.popupEndGame = new PopupWelcomeEndGame(this);
        this.add.existing(this.popupEndGame);
        this.popupEndGame.open();
      }
    });

    this.addGlobalEventListener('g-set-claimed-free-thugs', ({ claimedFreeThugs, seasonStatus }) => {
      if (!claimedFreeThugs && seasonStatus === 'open') {
        this.startGamePopup.open = 'free-thugs';
        this.startGamePopup.data = {};
      }
      this.startGamePopup.claimedFreeThugLoaded = true;

      this.openFirstPopup();
    });

    this.addGlobalEventListener(
      'g-set-user-away-reward',
      ({ noShow, showWarPopup, claimableReward, generatedNetworth }) => {
        if (noShow) {
          this.startGamePopup.welcomeBackLoaded = true;
          this.openFirstPopup();
          return;
        }
        this.popupWelcome = showWarPopup
          ? new PopupWelcomeWar(this, claimableReward, generatedNetworth)
          : new PopupWelcomeNoWar(this, claimableReward, generatedNetworth);
        this.add.existing(this.popupWelcome);
        if (!this.startGamePopup.open) {
          this.startGamePopup.open = 'welcome-back';
          this.startGamePopup.data = {};
        }

        this.startGamePopup.welcomeBackLoaded = true;

        this.openFirstPopup();
      }
    );
  }

  emitStartingEvents() {
    const startingEvents = [
      'g-get-season-status',
      'g-get-networth-house',
      'g-get-balances',
      'g-get-x-token-balance',
      'g-get-poor-token-balance',
      'g-get-auth',
      'g-get-next-war-time',
      'g-get-rank',
      'g-get-claimed-free-thugs',
      'g-get-claimable-reward',
      'g-get-claim-time',
      'g-get-claimable-status',
      'g-get-claim-cool-down',
    ];

    if (!this.isFromTutorial) {
      startingEvents.push('g-get-user-away-reward');
    }

    for (const event of startingEvents) {
      this.game.events.emit(event);
    }
  }

  // remove global event listeners when scene destroy
  removeListeners() {
    for (const event of this.globalEvents) {
      this.game.events.removeListener(event);
    }
  }

  shutdown() {
    this.removeListeners();
  }

  preload() {
    this.load.script('chartjs', 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/3.8.0/chart.min.js');

    this.bgMusic = this.sound.add('bg', { loop: true, volume: 0.15 });

    this.background = new Background(this, 'bg-xmas');
    this.add.existing(this.background);

    this.animationLayer = new Animation(this); // done
    this.add.existing(this.animationLayer);

    const gangsterHouse = new GangsterHouse(this, 2200); // done
    this.add.existing(gangsterHouse);

    this.popupDepositETH = new PopupDepositETH(this); // done
    this.add.existing(this.popupDepositETH);

    this.popupDeposit = new PopupDeposit(this); // done
    this.add.existing(this.popupDeposit);

    this.popupBuy = new PopupBuy(this, width - 335, 1330); // done
    this.add.existing(this.popupBuy);

    const header = new Header(this, 250); // done
    this.add.existing(header);

    this.popupWar = new PopupWar(this, 35, 1600); // done
    this.add.existing(this.popupWar);

    this.popupSettings = new PopupSettings(this); // done
    this.add.existing(this.popupSettings);

    this.popupSwap = new PopupSwap(this, this.popupSettings); // done
    this.add.existing(this.popupSwap);

    this.popupReferralProgram = new PopupReferralProgram(this); // done
    this.add.existing(this.popupReferralProgram);

    this.popupStarterPack = new PopupStarterPack(this); // done
    this.add.existing(this.popupStarterPack);

    this.popupAugments = new PopupAugments(this);
    this.add.existing(this.popupAugments);

    this.popupAugmentsPending = new PopupAugmentsPending(this);
    this.add.existing(this.popupAugmentsPending);

    this.popupPortfolio = new PopupPortfolio(this, this.popupSettings); // done
    this.add.existing(this.popupPortfolio);

    this.popupStatistic = new PopupStatistic(this); // done
    this.add.existing(this.popupStatistic);

    this.popupWarAttackDetail = new PopupWarAttackDetail(this); // done
    this.add.existing(this.popupWarAttackDetail);

    this.popupWarHistoryDetail = new PopupWarHistoryDetail(this); // done
    this.add.existing(this.popupWarHistoryDetail);

    this.popupWarUpgrades = new PopupWarUpgrades(this); // done
    this.add.existing(this.popupWarUpgrades);

    this.popupRewards = new PopupRewards(this); // done
    this.add.existing(this.popupRewards);

    if (this.game.sound.mute) {
      this.bgMusic.stop();
    } else {
      this.bgMusic.play();
    }

    const pluginLoader = this.load.scenePlugin({
      key: 'rexuiplugin',
      url: '/libs/rexui.min.js',
      sceneKey: 'rexUI',
    });
    pluginLoader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.popupAirdrop = new PopupAirdrop(this); // done
      this.add.existing(this.popupAirdrop);

      this.popupLinkingMetamask = new PopupLinkingMetamask(this); // done
      this.add.existing(this.popupLinkingMetamask);

      this.popupGoonPrice = new PopupGoonPrice(this); // done
      this.add.existing(this.popupGoonPrice);

      this.popupSafehousePrice = new PopupSafehousePrice(this); // done
      this.add.existing(this.popupSafehousePrice);

      this.popupGangsterPrice = new PopupGangsterPrice(this); // done
      this.add.existing(this.popupGangsterPrice);

      this.popupSafeHouseUpgrade = new PopupSafeHouseUpgrade(this); // done
      this.add.existing(this.popupSafeHouseUpgrade);

      this.popupBuyGoon = new PopupBuyGoon(this); // done
      this.add.existing(this.popupBuyGoon);

      this.popupBuyThug = new PopupBuyThug(this); // done
      this.add.existing(this.popupBuyThug);

      this.popupBuyGangster = new PopupBuyGangster(this); // done
      this.add.existing(this.popupBuyGangster);

      this.popupLeaderboard = new PopupLeaderboard(this); // done
      this.add.existing(this.popupLeaderboard);

      this.popupPrizePool = new PopupPrizePool(this); // done
      this.add.existing(this.popupPrizePool);

      this.popupWarHistory = new PopupWarHistory(this); // done
      this.add.existing(this.popupWarHistory);

      this.popupWarExplain = new PopupWarExplain(this); // done
      this.add.existing(this.popupWarExplain);

      this.popupWarMachines = new PopupWarMachines(this); // done
      this.add.existing(this.popupWarMachines);

      this.popupWarAttack = new PopupWarAttack(this); // done
      this.add.existing(this.popupWarAttack);

      this.popupDailySpin = new PopupDailySpin(this); // done
      this.add.existing(this.popupDailySpin);

      this.popupSpinHistory = new PopupSpinHistory(this); // done
      this.add.existing(this.popupSpinHistory);

      this.footer = new Footer(this, 2600); // done
      this.footer.setDepth(1);
      this.add.existing(this.footer);
    });

    this.popupSpinReward = new PopupSpinReward(this); // done
    this.popupSpinReward.setDepth(2);
    this.add.existing(this.popupSpinReward);

    this.infoButtons = new InfoButtons(this, 550); // done
    this.infoButtons.setDepth(2);
    this.add.existing(this.infoButtons);

    this.popupThugAirdrop = new PopupThugAirdrop(this); // done
    this.popupThugAirdrop.setDepth(2);
    this.add.existing(this.popupThugAirdrop);

    this.popupClaimFreeThug = new PopupClaimFreeThug(this); // done
    this.popupClaimFreeThug.setDepth(2);
    this.add.existing(this.popupClaimFreeThug);

    this.popupLinkX = new PopupLinkX(this); // done
    this.popupLinkX.setDepth(2);
    this.add.existing(this.popupLinkX);

    this.popupGangwarRequirement = new PopupGangwarRequirement(this); // done
    this.popupGangwarRequirement.setDepth(2);
    this.add.existing(this.popupGangwarRequirement);

    this.popupClaimConfirm = new PopupClaimConfirm(this);
    this.add.existing(this.popupClaimConfirm);

    this.popupGDLDeposit = new PopupGDLDeposit(this);
    this.add.existing(this.popupGDLDeposit);

    this.popupPrivyDeposit = new PopupPrivyDeposit(this);
    this.add.existing(this.popupPrivyDeposit);

    this.popupWarUpgradeExplain = new PopupWarUpgradeExplain(this);
    this.add.existing(this.popupWarUpgradeExplain);

    this.popupDepositConfirm = new PopupDepositConfirm(this);
    this.add.existing(this.popupDepositConfirm);

    if (this.isFromTutorial) {
      this.startGamePopup.welcomeBackLoaded = true;
    }
  }

  create() {
    this.initListeners();
    this.emitStartingEvents();
  }

  openFirstPopup() {
    if (this.status !== 'open') return;
    const { welcomeBackLoaded, claimedFreeThugLoaded, starterPackLoaded, open, data, hasOpened } = this.startGamePopup;
    if (!claimedFreeThugLoaded || !starterPackLoaded || !welcomeBackLoaded || hasOpened) return;

    if (open === 'free-thugs') {
      this.popupClaimFreeThug?.open(data);
      this.startGamePopup.hasOpened = true;
    }

    if (open === 'starter-pack') {
      this.popupStarterPack?.open(data);
      this.startGamePopup.hasOpened = true;
    }

    if (open === 'welcome-back') {
      this.popupWelcome?.open(data);
      this.startGamePopup.hasOpened = true;
    }
  }
}

export default MainScene;
