import { useMemo } from 'react';
import { faker } from '@faker-js/faker';

import useUserStore from '../stores/user.store';

const mockUsers = Array.from({ length: 7 }, (_, index) => ({
  avatarURL: faker.internet.avatar(),
  id: faker.string.uuid(),
  networth: faker.number.int({ min: 0, max: 1000 }),
  uPoint: faker.number.int({ min: 0, max: 1000 }),
  username: faker.internet.userName(),
  active: true,
  rank: index + 1,
}));

const useSimulator = () => {
  const user = useUserStore((state) => state.profile);

  const leaderboardData = useMemo(() => {
    if (!user) return [];

    const allUsers = [
      ...mockUsers,
      {
        avatarURL: user.avatarURL,
        id: faker.string.uuid(),
        networth: 0,
        totalRewardInEth: 0,
        stashValue: 0,
        userId: user.id,
        username: user.username,
        isUser: true,
        xUReward: 0,
        blastGoldReward: 0,
        bonusValueInEth: 0,
      },
    ].sort((user1, user2) => user2.networth - user1.networth);

    const totalNetworth = allUsers.reduce((sum, doc) => sum + doc.networth, 0);
    const ethPrizePool = 10;

    return allUsers.map((u, index) => {
      return {
        ...u,
        rank: index + 1,
        totalRewardInEth: (u.networth / totalNetworth) * ethPrizePool,
      };
    });
  }, [user?.avatarURL, user?.username, user?.id]);

  const leaderboard = {
    users: { reputation: leaderboardData, stash: [] },
    userRecord: {
      reputation: leaderboardData[leaderboardData.length - 1],
      stash: leaderboardData[leaderboardData.length - 1],
    },
    totalPages: {
      reputation: 1,
      stash: 1,
    },
  };

  return { leaderboard };
};

export default useSimulator;
