import Popup from './Popup';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { formatter } from '../../../../utils/numbers';
import { colors, fontFamilies } from '../../../../utils/styles';

const { width, height } = configs;

class PopupWelcomeWar extends Popup {
  latestWar = {};
  numberOfMachinesToEarn = 0;
  loading = false;

  constructor(scene, value, generatedNetworth) {
    super(scene, 'popup-welcome-war', {
      // openOnCreate: true,
      destroyWhenClosed: true,
      ribbon: 'ribbon-welcome',
      hasGlow: true,
      onClose: () => scene.events.emit('s-update-last-time-seen-war-result'),
    });

    this.line1Y = height / 2 + 370;
    this.line2Y = this.line1Y + 60;
    this.line3Y = this.line2Y + 40;

    const reputationY = this.popup.y - 140;
    this.numberGap = 370;
    const lineGap = 72;
    const defendTextX = width / 2 + 50;
    const defendIconX = defendTextX + 40;
    const attackTextX = defendTextX + this.numberGap;
    const attackIconX = attackTextX + 40;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const topMargin = this.popup.y - this.popup.height / 2;

    this.valueText = scene.add
      .text(
        leftMargin + this.popup.width * 0.28,
        topMargin + this.popup.height * 0.2,
        `+${formatter.format(value)}\nxGOLD`,
        {
          fontSize: '88px',
          color: '#fff',
          fontFamily: fontFamilies.extraBold,
          align: 'center',
        }
      )
      .setOrigin(0.5, 0);
    this.valueText.setStroke('#389d2a', 20);
    this.add(this.valueText);

    this.reputation = scene.add
      .text(width / 2 - 30, reputationY, `+${generatedNetworth.toLocaleString()}`, {
        fontSize: '72px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0, 0.5);
    this.reputationStar = scene.add
      .image(this.reputation.x + this.reputation.width + 30, reputationY, 'icon-star-medium')
      .setOrigin(0, 0.5);
    this.add(this.reputation);
    this.add(this.reputationStar);

    this.earnValueText = scene.add
      .text(width / 2 - this.numberGap, this.line1Y, '0', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.earnValueText);
    this.earnTokenText = scene.add
      .text(width / 2 - this.numberGap, this.line2Y, 'xGOLD', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.earnTokenText);

    this.defendNoLossText = scene.add
      .text(width / 2, this.line1Y, 'No Loss', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.defendNoLossText);

    this.defendXGoldText = scene.add
      .text(defendTextX, this.line1Y, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.defendXGoldIcon = scene.add
      .image(defendIconX, this.line1Y, 'icon-xgang-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.defendXGoldText);
    this.add(this.defendXGoldIcon);

    this.defendEthText = scene.add
      .text(defendTextX, this.line1Y + lineGap, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.defendEthIcon = scene.add
      .image(defendIconX, this.line1Y + lineGap, 'icon-eth-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.defendEthText);
    this.add(this.defendEthIcon);

    this.defendGoldText = scene.add
      .text(defendTextX, this.line1Y + lineGap * 2, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.defendGoldIcon = scene.add
      .image(defendIconX, this.line1Y + lineGap * 2, 'icon-coin-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.defendGoldText);
    this.add(this.defendGoldIcon);

    this.attackNoAttackText = scene.add
      .text(width / 2 + this.numberGap, this.line1Y, 'No Attack', {
        fontSize: '56px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.add(this.attackNoAttackText);
    this.attackXGoldText = scene.add
      .text(attackTextX, this.line1Y, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.attackXGoldIcon = scene.add
      .image(attackIconX, this.line1Y, 'icon-xgang-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.attackXGoldText);
    this.add(this.attackXGoldIcon);

    this.attackEthText = scene.add
      .text(attackTextX, this.line1Y + lineGap, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.attackEthIcon = scene.add
      .image(attackIconX, this.line1Y + lineGap, 'icon-eth-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.attackEthText);
    this.add(this.attackEthIcon);

    this.attackGoldText = scene.add
      .text(attackTextX, this.line1Y + lineGap * 2, '0', {
        fontSize: '40px',
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(1, 0.5)
      .setVisible(false);
    this.attackGoldIcon = scene.add
      .image(attackIconX, this.line1Y + lineGap * 2, 'icon-coin-small')
      .setDisplaySize(60, 60)
      .setVisible(false);
    this.add(this.attackGoldText);
    this.add(this.attackGoldIcon);

    scene.events.on('s-set-war-history-latest', (data) => {
      // do nothing if popup is already closed (& destroyed)
      if (!data || !this.active) return;

      this.reset();
      const {
        tokenEarnFromEarning,
        numberOfMachinesToEarn,
        tokenEarnFromAttacking,
        tokenStolen,
        attackUsers,
        defendResults,
        attackResults,
        stashChange,
      } = data;
      const defendStashEthChange = defendResults.reduce((result, item) => result + item.stashChange.eth || 0, 0);
      const defendStashTokenChange = defendResults.reduce((result, item) => result + item.stashChange.token || 0, 0);
      const attackStashEthChange = attackResults.reduce((result, item) => result + item.stashChange.eth || 0, 0);
      const attackStashTokenChange = attackResults.reduce((result, item) => result + item.stashChange.token || 0, 0);

      // earn
      this.earnValueText.text = `+${formatter.format(tokenEarnFromEarning || 0)}`;
      this.earnValueText.setVisible(true);
      this.earnTokenText.setVisible(true);
      this.numberOfMachinesToEarn = numberOfMachinesToEarn;

      // defend
      if (!tokenStolen && !defendStashEthChange && !stashChange.token) {
        this.defendNoLossText.setVisible(true);
      } else {
        this.defendXGoldText.text = `-${formatter.format(tokenStolen)}`;
        this.defendEthText.text = `${
          defendStashEthChange > 0 ? '+' : defendStashEthChange === 0 ? '-' : ''
        }${formatter.format(defendStashEthChange)}`;
        this.defendGoldText.text = `${
          defendStashTokenChange > 0 ? '+' : defendStashTokenChange === 0 ? '-' : ''
        }${formatter.format(defendStashTokenChange)}`;

        this.defendXGoldText.setVisible(true);
        this.defendXGoldIcon.setVisible(true);
        this.defendEthText.setVisible(true);
        this.defendEthIcon.setVisible(true);
        this.defendGoldText.setVisible(true);
        this.defendGoldIcon.setVisible(true);
      }

      // attack
      if (!attackUsers?.length) {
        this.attackNoAttackText.setVisible(true);
      } else {
        this.attackXGoldText.text = `-${formatter.format(tokenEarnFromAttacking)}`;
        this.attackEthText.text = `${attackStashEthChange >= 0 ? '+' : ''}${formatter.format(attackStashEthChange)}`;
        this.attackGoldText.text = `${attackStashTokenChange >= 0 ? '+' : ''}${formatter.format(
          attackStashTokenChange
        )}`;

        this.attackXGoldText.setVisible(true);
        this.attackXGoldIcon.setVisible(true);
        this.attackEthText.setVisible(true);
        this.attackEthIcon.setVisible(true);
        this.attackGoldText.setVisible(true);
        this.attackGoldIcon.setVisible(true);
      }
    });

    scene.events.on('s-set-claimable-status', ({ claimable, active }) => {
      // do nothing if popup is already closed (& destroyed)
      if (!this.active) return;

      if (this.buttonClaim) {
        this.remove(this.buttonClaim);
        this.buttonClaim.destroy(true);
      }
      this.buttonClaim = new TextButton(
        scene,
        width / 2,
        height / 2 + this.popup.height / 2 - 20,
        'button-blue',
        'button-blue-pressed',
        () => this.close(),
        'Back',
        { sound: 'close', fontSize: '82px' }
      );
      this.add(this.buttonClaim);
    });

    scene.events.emit('s-get-war-history-latest');
    scene.events.emit('s-get-claimable-status');
  }

  reset() {
    this.earnValueText.setVisible(false);
    this.earnTokenText.setVisible(false);
    this.defendNoLossText.setVisible(false);
    this.defendXGoldText.setVisible(false);
    this.defendXGoldIcon.setVisible(false);
    this.defendEthText.setVisible(false);
    this.defendEthIcon.setVisible(false);
    this.defendGoldText.setVisible(false);
    this.defendGoldIcon.setVisible(false);
    this.attackNoAttackText.setVisible(false);
    this.attackXGoldText.setVisible(false);
    this.attackXGoldIcon.setVisible(false);
    this.attackEthText.setVisible(false);
    this.attackEthIcon.setVisible(false);
    this.attackGoldText.setVisible(false);
    this.attackGoldIcon.setVisible(false);
  }
}

export default PopupWelcomeWar;
